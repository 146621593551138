import { Component, OnInit } from '@angular/core';
import {CSHelper} from '../../helpers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {LoginComponent} from '../login/login.component';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordFrm: FormGroup;
  countryCode = false;
  submitted = false;
  mobileNumber = false;
  countryCodeList = [];
  selectedCountry: any;
  public formValidationMessages: any = {
    email: []
  };

  constructor(public helpers: CSHelper,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService
              ) {
    this.createForm();
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe(value =>  {
      this.countryCodeList = value.data;
      this.forgotPasswordFrm.controls.countryCode.setValue(value.data[0].phone_prefix);
      this.selectedCountry = value.data[0].phone_prefix;
    });
  }

  numericCheck(value) {
    const pattern = /^\d+$/;
    pattern.test(value) ? this.countryCode = true : this.countryCode = false;
    if (pattern.test(value) && value.length >= 5) {
      this.helpers.minEmailAddress = this.helpers.maxMobileNumber;
      this.mobileNumber = false;
      this.forgotPasswordFrm.controls.email.clearValidators();
      this.forgotPasswordFrm.controls.email.updateValueAndValidity();
    } else {
      this.helpers.minEmailAddress = 150;
      if (this.countryCode === true) {
        this.mobileNumber = true;
        this.forgotPasswordFrm.controls.email.clearValidators();
      }
      this.forgotPasswordFrm.controls.email.setValidators      ([Validators.required, Validators.pattern(this.helpers.emailPattern)]);
      this.forgotPasswordFrm.controls.email.updateValueAndValidity();
    }
  }

  private createForm() {
    this.forgotPasswordFrm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern(this.helpers.emailPattern),
      ]],
      countryCode: []
    });
    this.formValidationMessages = {
      email: [
        {type: 'required', message: 'please_enter_your_email_address_or_mobile_number'},
        {type: 'pattern', message: 'please_enter_valid_email_address'},
      ]
    };
  }

  forgotPassword() {
    this.submitted = true;

    if (this.forgotPasswordFrm.valid && this.mobileNumber !== true) {

      const formData = new FormData();
      if (this.countryCode) {
        formData.append('mobile', this.forgotPasswordFrm.get('email').value);
        formData.append('type', '2');
        formData.append('country_code', this.forgotPasswordFrm.get('countryCode').value);
      } else {
        formData.append('type', '1');
        formData.append('email', this.forgotPasswordFrm.get('email').value);
      }
      this.spinner.show();
      this.apiService.forgotPassword(formData).subscribe((value: any) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        } else {
          if (this.countryCode) {
            this.helpers.country_code = this.forgotPasswordFrm.get('countryCode').value;
            this.helpers.mobile = this.forgotPasswordFrm.get('email').value;
            this.helpers.resetFor = 'contact';
          } else {
            this.helpers.email = this.forgotPasswordFrm.get('email').value;
            this.helpers.resetFor = 'email';
          }
          this.toastr.success(value.meta.message);
          this.modalService.open(ResetPasswordComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
          this.activeModal.dismiss();
        }
      });
    }
  }

  changeCountryCode(e) {
    this.forgotPasswordFrm.controls.countryCode.setValue(e.target.value);
  }

  login() {
    this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    this.activeModal.dismiss();
  }

}
