import { Component, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router} from "@angular/router";
import {TravelAgentRegistrationComponent} from '../travel-agent-registration/travel-agent-registration.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CSHelper} from "../../helpers";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  myDate;

  constructor(private datePipe: DatePipe, private modalService: NgbModal, private router: Router, public helpers: CSHelper) {
    const date = new Date();
    this.myDate = this.datePipe.transform(date, 'yyyy');
  }

  ngOnInit() {
  }

  cms(url) {
    const route = url;
    this.router.navigate([route.trim()]);
  }

  travelAgencyJoinNow() {
    this.modalService.open(TravelAgentRegistrationComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
  }

}
