import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-success-page",
  templateUrl: "./success-page.component.html",
  styleUrls: ["./success-page.component.scss"],
})
export class SuccessPageComponent implements OnInit {
  ref_no: string;
  amount: number;
  currency: string;
  tour_name: string;
  payment_option: string;
  remote_payment_id: string;
  payment_status: string;
  transaction_date: string;
  name: string;
  mobile: string;
  email: string;
  bookingData: string;
  formData: FormData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.ref_no = this.route.snapshot.paramMap.get("ref_no");
    this.bookingData = JSON.parse(localStorage.getItem("bookingData"));
    this.formData = new FormData();
    Object.keys(this.bookingData).forEach((key) =>
      this.formData.append(key, this.bookingData[key])
    );
  }

  ngOnInit() {
    this.spinner.show();

    // Get the payment details using reference number
    this.apiService
      .getPaymentDetails(this.ref_no)
      .subscribe((paymentDetails) => {
        this.spinner.hide();

        this.transaction_date = paymentDetails["created_at"];
        this.name = paymentDetails["name"];
        this.email = paymentDetails["user_email"];
        this.mobile = paymentDetails["mobile"];
        this.ref_no = paymentDetails["ref_no"];
        this.amount = paymentDetails["amount"];
        this.currency = paymentDetails["currency"];
        this.tour_name = paymentDetails["tour_id"];
        this.payment_option = paymentDetails["payment_option"];
        this.remote_payment_id = paymentDetails["remote_payment_id"];
        this.payment_status = paymentDetails["payment_status"];
      });

    this.formData.append("ref_no", this.ref_no);

    // Create the tour booking for the user
    this.apiService.postBookTour(this.formData).subscribe((value) => {
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message);
      // localStorage.removeItem("memberDetails");
      // localStorage.removeItem("bookTourData");
      // localStorage.removeItem("bookingData");
    });
  }

  downloadReceipt() {
    window.print();
  }

  viewMyBookings() {
    this.router.navigate(["my-booking"]);
  }
}
