import {Component, Input, OnInit} from '@angular/core';
import {CSHelper} from '../../helpers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {VerifyMobileNumberComponent} from '../verify-mobile-number/verify-mobile-number.component';

@Component({
  selector: 'app-edit-profile-detail',
  templateUrl: './edit-profile-detail.component.html',
  styleUrls: ['./edit-profile-detail.component.scss']
})
export class EditProfileDetailComponent implements OnInit {

  @Input() public userDetails;
  editProfileFrm: FormGroup;
  submitted = false;
  countryCodeList: [];
  selectedCountry;
  public userImage = this.helpers.url;
  public formValidationMessages: any = {
    name: [],
    email: [],
    mobile: [],
  };

  constructor(public helpers: CSHelper,
              private formBuilder: FormBuilder,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              ) {
    this.createForm();
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe(value =>  {
      this.countryCodeList = value.data;
    });
    if (this.userDetails.profile_image) {
      this.formValidationMessages.profile_image = [];
      this.editProfileFrm.controls.profile_image.setValidators(null);
      this.editProfileFrm.controls.profile_image.setErrors(null);
      this.userImage = this.userDetails.profile_image;
    }
    ['name', 'email', 'mobile', 'country_code'].map(value => {
      this.editProfileFrm.controls[value].setValue(this.userDetails[value]);
    });
    this.selectedCountry = this.userDetails.country_code;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userImage = reader.result;
        this.editProfileFrm.patchValue({
          profile_image: file
        });
      };
    }

  }

  private createForm() {
    this.editProfileFrm = this.formBuilder.group({
      profile_image: [''],
      name: ['', [
        Validators.required,
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(this.helpers.emailPattern),
      ]],
      mobile: ['', [
        Validators.required,
        Validators.minLength(this.helpers.minMobileNumber),
      ]],
      country_code: []
    });
    this.formValidationMessages = {
      name: [
        {type: 'required', message: 'please_enter_your_name'},
      ],
      email: [
        {type: 'required', message: 'please_enter_your_email_address'},
        {type: 'pattern', message: 'please_enter_a_valid_email_address'},
      ],
      mobile: [
        {type: 'required', message: 'please_enter_your_mobile_number'},
        {type: 'minlength', message: 'mobile_number_should_be_between_5_to_15_digits'},
      ]
    };
  }

  changeCountryCode(e) {
    this.editProfileFrm.controls.country_code.setValue(e.target.value);
  }

  editProfile() {
    this.submitted = true;
    if (this.editProfileFrm.valid) {
      const formData = new FormData();
      const data = ['name', 'email', 'mobile', 'country_code'];
      data.map(value => {
        formData.append(value, this.editProfileFrm.get(value).value);
      });
      if (this.editProfileFrm.get('profile_image').value !== '') {
        formData.append('profile_image', this.editProfileFrm.get('profile_image').value);
      }
      this.spinner.show();
      this.apiService.editProfile(formData).subscribe(value => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }

        // set nav bar user name and logo after update profile
        this.apiService.subject.next({isLoggedIn: true, userName: value.data.name, userImage: value.data.profile_image, profileUpdated: true});
        localStorage.setItem('userName', value.data.name);
        localStorage.setItem('userImage', value.data.profile_image);

        // if mobile number changed, need to verify it
        if (value.data.mobile_verification !== null) {
          this.helpers.mobile = value.data.mobile_verification.mobile;
          this.helpers.country_code = value.data.mobile_verification.country_code;
          this.helpers.verifyFromLogin = true;
          const modalRef = this.modalService.open(VerifyMobileNumberComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
          modalRef.componentInstance.updatedProfile = true;
        }
        this.toastr.success(value.meta.message);
        this.activeModal.close();
      });
    }
  }

}
