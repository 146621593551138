import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TravelAgentRegistrationComponent } from "../../shared/travel-agent-registration/travel-agent-registration.component";

@Component({
  selector: "app-travel-agency-landing-page",
  templateUrl: "./travel-agency-landing-page.component.html",
  styleUrls: ["./travel-agency-landing-page.component.scss"],
})
export class TravelAgencyLandingPageComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  travelAgencyJoinNow() {
    this.modalService.open(TravelAgentRegistrationComponent, {
      size: "xl",
      windowClass: "common-popup",
      backdropClass: "modal-backdrop-custom",
    });
  }
}
