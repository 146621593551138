import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CSHelper } from "../../helpers";
import { RegistrationComponent } from "../registration/registration.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { ApiService } from "../../api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { VerifyMobileNumberComponent } from "../verify-mobile-number/verify-mobile-number.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  submitted = false;
  loginFrm: FormGroup;
  countryCode = false;
  mobileNumber = false;
  countryCodeList = [];
  selectedCountry: any;
  public formValidationMessages: any = {
    email: [],
    password: [],
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public helpers: CSHelper,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe((value) => {
      this.countryCodeList = value.data;
      this.loginFrm.controls.countryCode.setValue(
        value.data.find((v) => v.phone_prefix === 86).phone_prefix
      );
      this.selectedCountry = value.data.find(
        (v) => v.phone_prefix === 86
      ).phone_prefix;
    });

    const emailField = document.getElementById("email") as HTMLInputElement;

    //preventing spaces when pressing space
    emailField.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === " ") {
        event.preventDefault();
      }
    });

    //preventing spaces when pasting text
    emailField.addEventListener("paste", (event: ClipboardEvent) => {
      const clipboardData = event.clipboardData;
      const pastedText = clipboardData.getData("text");
      const trimmedText = pastedText.replace(/\s/g, "");
      document.execCommand("insertText", false, trimmedText);
      event.preventDefault();
    });
  }

  private createForm() {
    this.loginFrm = this.formBuilder.group({
      email: [
        "",
        [Validators.required, Validators.pattern(this.helpers.emailPattern)],
      ],
      password: ["", [Validators.required, Validators.minLength(6)]],
      countryCode: [],
    });
    this.formValidationMessages = {
      email: [
        {
          type: "required",
          message: "please_enter_your_email_address_or_mobile_number",
        },
        { type: "pattern", message: "please_enter_valid_email_address" },
      ],
      password: [
        { type: "required", message: "please_enter_your_password" },
        { type: "minlength", message: "please_enter_a_valid_password" },
      ],
    };
  }

  numericCheck(value) {
    const pattern = /^\d+$/;
    pattern.test(value)
      ? (this.countryCode = true)
      : (this.countryCode = false);
    if (pattern.test(value) && value.length >= 5) {
      this.helpers.minEmailAddress = this.helpers.maxMobileNumber;
      this.mobileNumber = false;
      this.loginFrm.controls.email.clearValidators();
      this.loginFrm.controls.email.updateValueAndValidity();
    } else {
      this.helpers.minEmailAddress = 150;
      if (this.countryCode === true) {
        this.mobileNumber = true;
        this.loginFrm.controls.email.clearValidators();
      }
      this.loginFrm.controls.email.setValidators([
        Validators.required,
        Validators.pattern(this.helpers.emailPattern),
      ]);
      this.loginFrm.controls.email.updateValueAndValidity();
    }
  }
  changeCountryCode(e) {
    this.loginFrm.controls.countryCode.setValue(e.target.value);
  }

  loginForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginFrm.valid && this.mobileNumber !== true) {
      // api here

      const formData = new FormData();
      if (this.countryCode) {
        formData.append("mobile", this.loginFrm.get("email").value);
        formData.append("login_type", "2");
        formData.append("country_code", this.loginFrm.get("countryCode").value);
      } else {
        formData.append("login_type", "1");
        formData.append("email", this.loginFrm.get("email").value);
      }
      formData.append("password", this.loginFrm.get("password").value);
      this.spinner.show();
      this.apiService.loginApi(formData).subscribe((value) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        } else if (value.data.mobile_verified === 0) {
          this.helpers.mobile = value.data.mobile;
          this.helpers.country_code = value.data.country_code;
          this.helpers.verifyFromLogin = true;
          this.helpers.email = value.data.email;
          this.modalService.open(VerifyMobileNumberComponent, {
            size: "xl",
            windowClass: "common-popup",
            backdropClass: "modal-backdrop-custom",
          });
        } else {
          localStorage.setItem("userName", value.data.name);
          localStorage.setItem("userImage", value.data.profile_image);
          localStorage.setItem("token", value.meta.token);
          this.apiService.subject.next({
            isLoggedIn: true,
            userName: value.data.name,
            userImage: value.data.profile_image,
          });
        }
        this.activeModal.close();
        this.toastr.success(value.meta.message);
      });
    }
  }

  loginWithWechat() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    localStorage.setItem("lastUrl", window.location.href);

    this.apiService
      .getWechatLoginQr({ isMobile: isMobile })
      .subscribe((data) => {
        document.location.href = data.url;
      });
  }

  signUp() {
    this.modalService.open(RegistrationComponent, {
      size: "xl",
      windowClass: "common-popup",
      backdropClass: "modal-backdrop-custom",
    });
    this.activeModal.dismiss();
  }

  forgotPassword() {
    this.modalService.open(ForgotPasswordComponent, {
      size: "xl",
      windowClass: "common-popup",
      backdropClass: "modal-backdrop-custom",
    });
    this.activeModal.dismiss();
  }
}
