import {Component, OnInit} from '@angular/core';
import {CSHelper} from '../../helpers';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginComponent} from '../../shared/login/login.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.scss']
})
export class TourDetailComponent implements OnInit {

  tourId: any;
  tourDetails: any;
  previewSlots = [];
  previewReviews = [];
  moreSlots = false;
  moreReviews = false;
  displayDays: any;
  selectedPriceValue = 'budget_hotel';
  selectedSlot: any;

  constructor(public helpers: CSHelper,
              private activeRoute: ActivatedRoute,
              private apiService: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private titleService: Title,
              private router: Router,
  ) {
    this.helpers.logoScroll = false;
    this.tourId = activeRoute.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    this.getTourDetailsFunc();
    this.apiService.subject.subscribe(v => {
      if (v.isLoggedIn !== undefined || v.tourFavourite) {
        this.getTourDetailsFunc();
      }
    });
  }

  getTourDetailsFunc() {
    if (this.tourId) {
      this.apiService.getTourDetails(this.tourId).subscribe(value => {
        if (value.meta.code === 0) {
          return this.router.navigate(['not-found']);
        }
        this.titleService.setTitle(value.data.name);
        this.tourDetails = value.data;
        this.selectedSlot = value.data.tour_iterations[0];
        this.previewSlots = value.data.tour_iterations.slice(0, 4);
        this.previewReviews = value.data.reviews.slice(0, 1);
        if ( value.data.budget_price === 0 ) {
          if (value.data.standard_price === 0) {
            this.selectedPriceValue = 'luxury_hotel';
          } else {
            this.selectedPriceValue = 'standard_hotel';
          }
        } else {
          this.selectedPriceValue = 'budget_hotel';
        }
        this.filterDays();
      });
    }
  }

  copy(data) {
    this.helpers.copyToClipboard(data);
    this.toastr.success('Copied!');
  }
  viewMoreSlots() {
    this.moreSlots = true;
    this.previewSlots = this.tourDetails.tour_iterations;
  }
  viewMoreReviews() {
    this.moreReviews = true;
    this.previewReviews = this.tourDetails.reviews;
  }
  viewLess() {
    this.moreReviews = false;
    this.previewReviews = this.tourDetails.reviews.slice(0, 1);
  }
  makeTourFavourite(id, favourite) {
    if (localStorage.getItem('token')) {
      const status: any = favourite === 0 ? 1 : 0;
      const formData = new FormData();
      formData.append('tour_id', id);
      formData.append('status', status);
      this.apiService.postTourFavourite(formData).subscribe(value => {
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.apiService.subject.next({tourFavourite: true});
      });
    } else {
      this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    }
  }
  selectedPrice(e) {
    this.selectedPriceValue = e.target.value;
    this.filterDays();
  }
  filterDays() {
    this.displayDays = this.tourDetails.tour_days.filter((val) => {
      return val[this.selectedPriceValue] !== null;
    });
  }

  selectSlot(tour) {
    this.selectedSlot = tour;
  }
  handleBookNow() {
    if (localStorage.getItem('token')) {
      const selected = this.selectedPriceValue  === 'budget_hotel' ? 'budget_price' :
        this.selectedPriceValue  === 'standard_hotel' ? 'standard_price' :
          this.selectedPriceValue  === 'luxury_hotel' ? 'luxury_price' : '';

      const bookTourData = {
        name: this.tourDetails.name,
        type: this.selectedPriceValue,
        agency_id: this.tourDetails.agency_id,
        tour_id: this.tourDetails.url,
        iteration_id: this.selectedSlot.id,
        slot: this.selectedSlot,
        price: this.tourDetails[selected]
      };
      localStorage.setItem('bookTourData', JSON.stringify(bookTourData));
      this.router.navigate(['book-now']);
    } else {
      this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    }
  }

  checkHotelDisplay(type) {
    return (type === '2' && this.selectedPriceValue === 'budget_hotel') || (type === '1' && this.selectedPriceValue === 'standard_hotel') || (type === '3' && this.selectedPriceValue === 'luxury_hotel');
  }
}
