import { Component, Input, OnInit } from "@angular/core";
import { CSHelper } from "../../helpers";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";

@Component({
  selector: "app-verify-mobile-number",
  templateUrl: "./verify-mobile-number.component.html",
  styleUrls: ["./verify-mobile-number.component.scss"],
})
export class VerifyMobileNumberComponent implements OnInit {
  @Input() public updatedProfile;
  verifyMobileFrm: FormGroup;
  submitted = false;
  isEditable = false;
  countryCodeList = [];
  selectedCountry: any;
  timerInterval;
  timeLeft: number;

  public formValidationMessages: any = {
    mobile: [],
    otp: [],
  };

  constructor(
    public helpers: CSHelper,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.timeLeft = 0;
    this.verifyMobileFrm.controls.mobile.setValue(this.helpers.mobile);
    this.verifyMobileFrm.controls.country_code.setValue(
      this.helpers.country_code
    );
    this.selectedCountry = this.helpers.country_code;

    this.apiService.getCountryCode().subscribe((value) => {
      this.countryCodeList = value.data;
    });
  }

  private startCoolDownTimer() {
    this.timeLeft = 30;
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  private createForm() {
    this.verifyMobileFrm = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(this.helpers.minMobileNumber),
        ],
      ],
      otp: ["", [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      country_code: [],
    });
    this.formValidationMessages = {
      mobile: [
        { type: "required", message: "please_enter_your_mobile_number" },
        {
          type: "minlength",
          message: "mobile_number_should_be_between_5_to_15_digits",
        },
      ],
      otp: [
        { type: "required", message: "please_enter_verification_code" },
        { type: "pattern", message: "please_enter_valid_verification_code." },
      ],
    };
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  changeCountryCode(e) {
    this.verifyMobileFrm.setValue(e.target.value, { onlySelf: true });
  }

  updateMobile() {
    this.isEditable = !this.isEditable;
    if (!this.isEditable) {
      this.resendOtp("update");
    }
  }

  resendOtp(mode) {
    if (mode && mode !== "update") {
      this.submitted = true;
    }
    // This api is called when the normal login and signup flow is done
    if (this.verifyMobileFrm.controls.mobile.valid && !this.updatedProfile) {
      const formData = new FormData();
      formData.append("email", this.helpers.email);
      formData.append(
        "country_code",
        this.verifyMobileFrm.get("country_code").value
      );
      formData.append("mobile", this.verifyMobileFrm.get("mobile").value);

      this.spinner.show();
      this.apiService.resendOtp(formData).subscribe((value: any) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message, "Success");
        this.verifyMobileFrm.controls.otp.setValue("");
      });
      this.startCoolDownTimer();
    } else if (
      this.verifyMobileFrm.controls.mobile.valid &&
      this.updatedProfile
    ) {
      // This api is called when the user updated his profile
      const formData = new FormData();
      formData.append(
        "country_code",
        this.verifyMobileFrm.get("country_code").value
      );
      formData.append("mobile", this.verifyMobileFrm.get("mobile").value);

      this.spinner.show();
      this.apiService
        .updateProfileResendOtp(formData)
        .subscribe((value: any) => {
          this.spinner.hide();
          if (value.meta.code === 0) {
            return this.toastr.error(value.meta.message);
          }
          this.toastr.success(value.meta.message, "Success");
          this.verifyMobileFrm.controls.otp.setValue("");
        });
      this.startCoolDownTimer();
    }
  }

  verifyMobile() {
    this.submitted = true;

    // This api is called when the normal login and signup flow is done
    if (this.verifyMobileFrm.valid && !this.updatedProfile) {
      const data = ["mobile", "otp", "country_code"];
      const formData = new FormData();
      data.map((val) => {
        formData.append(val, this.verifyMobileFrm.get(val).value);
      });
      formData.append("email", this.helpers.email);
      this.spinner.show();
      this.apiService.verifyMobileNumber(formData).subscribe((value: any) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message, "Success", {
          timeOut: 3000,
        });
        if (value.data.mobile_verified === 1) {
          localStorage.setItem("userName", value.data.name);
          localStorage.setItem("userImage", value.data.profile_image);
          localStorage.setItem("token", value.meta.token);
          this.apiService.subject.next({
            isLoggedIn: true,
            userName: value.data.name,
            userImage: value.data.profile_image,
          });
          this.helpers.verifyFromLogin = false;
          this.helpers.mobile = "";
          this.helpers.country_code = "";
          this.helpers.email = "";
          this.activeModal.close();
        }
      });
    } else if (this.verifyMobileFrm.valid && this.updatedProfile) {
      // This api is called when the user updated his profile

      const formData = new FormData();
      const data = ["mobile", "otp", "country_code"];
      data.map((val) => {
        formData.append(val, this.verifyMobileFrm.get(val).value);
      });
      this.spinner.show();
      this.apiService
        .updateProfileMobileVerification(formData)
        .subscribe((value) => {
          this.spinner.hide();
          if (value.meta.code === 0) {
            return this.toastr.error(value.meta.message);
          }
          this.apiService.subject.next({
            profileUpdated: true,
            isLoggedIn: true,
            userImage: localStorage.getItem("userImage"),
          });
          this.toastr.success(value.meta.message);
          this.helpers.verifyFromLogin = false;
          this.helpers.mobile = "";
          this.helpers.country_code = "";
          this.helpers.email = "";
          this.activeModal.close();
        });
    }
  }
}
