import {Component, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  submitted = false;
  contactUsFrm: FormGroup;
  countryCodeList = [];
  selectedCountry: any;
  public formValidationMessages: any = {
    name: [],
    email: [],
    mobile: [],
    message: []
  };

  constructor(public helpers: CSHelper,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService
              ) {
    this.createForm();
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe(value =>  {
      this.countryCodeList = value.data;
      this.contactUsFrm.controls.country_code.setValue(value.data.find(v => v.phone_prefix === 86).phone_prefix);
      this.selectedCountry = value.data.find(v => v.phone_prefix === 86).phone_prefix;
    });
    this.getUserDetails();
    this.apiService.subject.subscribe(v => {
      if (v.isLoggedIn) {
        this.getUserDetails();
      } else {
        this.resetForm();
      }
    });
  }

  resetForm() {
    const arr = ['name', 'email', 'mobile', 'message'];
    arr.map(val => {
      this.contactUsFrm.controls[val].setValue('');
    });
    this.contactUsFrm.controls.country_code.setValue(86);
  }

  getUserDetails() {
    if (localStorage.getItem('token')) {
      const data = ['name', 'email', 'mobile', 'country_code'];
      this.spinner.show();
      this.apiService.getProfileDetails().subscribe(value => {
        this.spinner.hide();
        data.map(v => {
          this.contactUsFrm.controls[v].setValue(value.data[v]);
        });
      });
    }
  }

  private createForm() {
    this.contactUsFrm = this.formBuilder.group({
      name: ['', [
        Validators.required,
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(this.helpers.emailPattern),
      ]],
      mobile: ['', [
        Validators.required,
        Validators.minLength(this.helpers.minMobileNumber),
      ]],
      message: ['', [
        Validators.required,
      ]],
      country_code: ['']
    });
    this.formValidationMessages = {
      name: [
        {type: 'required', message: 'please_enter_your_name'},
      ],
      email: [
        {type: 'required', message: 'please_enter_your_email'},
        {type: 'pattern', message: 'please_enter_a_valid_email_address'},
      ],
      mobile: [
        {type: 'required', message: 'please_enter_your_contact_number'},
        {type: 'minlength', message: 'mobile_number_should_be_between_5_to_15_digits'},
      ],
      message: [
        {type: 'required', message: 'please_enter_some_message'},
      ],
    };
  }

  changeCountryCode(e) {
    this.contactUsFrm.controls.country_code.setValue(e.target.value);
  }

  contactUs() {
    this.submitted = true;
    if (this.contactUsFrm.valid) {
      const data = ['name', 'email', 'mobile', 'country_code', 'message'];

      const formData = new FormData();
      data.map(value => {
        formData.append(value, this.contactUsFrm.get(value).value);
      });
      this.spinner.show();
      this.apiService.postContactUs(formData).subscribe(value => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message);
        if (!localStorage.getItem('token')) {
          this.resetForm();
        } else {
          this.contactUsFrm.controls.message.setValue('');
        }
        this.submitted = false;
      });
    }
  }
}
