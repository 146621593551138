import {Component, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {ApiService} from '../../api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-promocodes',
  templateUrl: './promocodes.component.html',
  styleUrls: ['./promocodes.component.scss']
})
export class PromocodesComponent implements OnInit {

  promoCodesData = [];
  promoCodes;
  showMoreTours = false;
  closeResult: string;

  constructor(public helpers: CSHelper, private renderer: Renderer2, private apiService: ApiService, private modalService: NgbModal) {
    this.helpers.logoScroll = false;
    this.renderer.removeClass(document.body, 'header-background');
  }

  perPage = this.helpers.perPage
  ngOnInit() {
    this.promoCodeList(this.perPage);
  }

  promoCodeList(perPage) {
    this.apiService.getPromoCodeList({per_page: perPage}).subscribe((value) => {
      value.meta.total <= 0 ? this.helpers.noDataFound = true : this.helpers.noDataFound = false;
      this.showMoreTours = value.meta.total > this.perPage;
      this.promoCodesData = value.data
      if (value.meta.total === value.meta.from) {
        this.showMoreTours = false;
      }
    });
  }

  promoDescriptionMore(content, data) {
    this.promoCodes = data;
    this.modalService.open(content, {centered: true , windowClass: 'description-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  loadMoreData() {
    this.perPage = this.perPage + this.helpers.loadMore
    this.promoCodeList(this.perPage);
  }

}
