import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';
import {CancelBookingComponent} from "../../shared/cancel-booking/cancel-booking.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {

  membersDetails = [];
  memberCount;
  currentRate = 0;
  state$: Observable<object>;
  detail: any = null;
  userImage = localStorage.getItem('userImage');
  userName = localStorage.getItem('userName');
  userReview: any = '';

  constructor(public helpers: CSHelper,
              private renderer: Renderer2,
              private router: Router,
              private el: ElementRef,
              public activeRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private apiService: ApiService,
              private toastr: ToastrService,
              public modalService: NgbModal
              ) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
    this.memberCount = this.membersDetails.length;
  }

  ngOnInit() {
    if (history.state.bookingDetails === true) {
      const  scrollElement = this.el.nativeElement.querySelector('.rating-main');
      scrollElement.scrollIntoView({behavior: 'smooth'});
    }
    this.getBookingDetails();
  }

  getBookingDetails() {
    this.spinner.show();
    const id = this.activeRoute.snapshot.paramMap.get('id');
    this.apiService.getBookingDetails(id).subscribe(value => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.router.navigate(['not-found']);
      }
      this.detail = value.data;
    });
  }

  cms(url) {
    const route = url;
    this.router.navigate([route.trim()]);
  }

  getRating(val) {
    this.currentRate = val;
  }

  submitReview() {
    if (this.userReview.length > 0 && this.currentRate > 0) {
      const formData = new FormData();
      formData.append('booking_id', this.detail.booking_id);
      formData.append('rating', JSON.stringify(this.currentRate));
      formData.append('review', this.userReview);
      this.apiService.postAddTourReview(formData).subscribe(value => {
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message);
        this.getBookingDetails();
      });
    } else {
      this.toastr.error('Please fill your valuable feedback and rating both!');
    }
  }

  cancelBooking(id) {
    this.modalService.open(CancelBookingComponent,
      {size: 'sm', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
      if (result === 'confirm') {
        this.cancelBookingTour(id);
      }
    });
  }
  cancelBookingTour(id) {
    this.spinner.show();
    this.apiService.getCancelBooking(id).subscribe(value => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message);
      this.getBookingDetails();
    });
  }

}
