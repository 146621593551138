import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { ApiService } from "../../api.service";
import { ToastrService } from "ngx-toastr";
import { CSHelper } from "../../helpers";

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],
})
export class PaymentDetailsComponent implements OnInit, OnChanges {
  @Input() bookTourData;
  @Input() memberLength;
  @Input() bookNow;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  paymentData = {};
  applyPromoCode = false;
  appliedPromoCode = false;
  promoCodeValue: any;
  promoCodeData: any;
  paymentMethod: string;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    public helpers: CSHelper
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {}

  promoCode(value: HTMLInputElement, event: any) {
    const wLenght = value.value.length;
    this.promoCodeValue = value.value;
    wLenght > 0 ? (this.applyPromoCode = true) : (this.applyPromoCode = false);
  }

  applyPromoCodes() {
    if (this.applyPromoCode) {
      this.apiService
        .postApplyPromoCode({
          promo_code: this.promoCodeValue,
          agency_id: this.bookTourData.agency_id,
          tour_id: this.bookTourData.tour_id,
        })
        .subscribe((value) => {
          if (value.meta.code === 0) {
            return this.toastr.error(value.meta.message);
          }
          this.toastr.success(value.meta.message);
          this.promoCodeData = value.data;
          this.appliedPromoCode = true;
        });
    }
  }

  removePromoCode() {
    this.appliedPromoCode = false;
    this.applyPromoCode = false;
    this.promoCodeValue = null;
    this.promoCodeData = null;
    this.toastr.warning("Promocode removed successfully.");
  }

  payNow() {
    if (this.memberLength && this.memberLength > 0) {
      this.paymentData["promoCodeData"] = this.promoCodeData;
    } else {
      this.toastr.error("Please add members for your selected tour.");
    }

    try {
      this.paymentMethod = (
        document.querySelector(
          'input[name="payment-methods"]:checked'
        ) as HTMLFormElement
      ).value;
    } catch (e) {
      this.toastr.error("Please select a payment method.");
    }

    if (this.paymentMethod !== undefined) {
      this.paymentData["paymentMethod"] = this.paymentMethod;
      this.emitter.emit(this.paymentData);
    }
  }
}
