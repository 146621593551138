import {Component, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CancelBookingComponent} from '../../shared/cancel-booking/cancel-booking.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-my-booking',
  templateUrl: './my-booking.component.html',
  styleUrls: ['./my-booking.component.scss']
})
export class MyBookingComponent implements OnInit {

  currentRate = 3;
  myBookingList: any = [];
  selectedFilter = 0;
  selectedFilterStr = 'all_bookings';
  searchResult = '';
  perPage = 6;
  showLoadMore = false;

  constructor(public helpers: CSHelper,
              private renderer: Renderer2,
              private router: Router,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private toastr: ToastrService
              ) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
   this.getMyBookingList();
  }

  getMyBookingList() {
    const params: any = {};
    params.type = this.selectedFilter;
    params.per_page = this.perPage;
    if (this.searchResult !== '') { params.search = this.searchResult; }
    this.spinner.show();
    this.apiService.getBookingList(params).subscribe(value => {
      this.spinner.hide();
      this.showLoadMore = value.meta.total > this.perPage;
      if (value.meta.code === 1) {
        this.myBookingList = value.data;
      }
    });
  }

  cms(url) {
    this.router.navigate([url.trim()]);
  }

  addRateReview() {
    this.router.navigateByUrl('booking-details', { state: { bookingDetails: true } });
  }

  handleSearch(e) {
    this.searchResult = e.target.value;
    this.getMyBookingList();
  }

  filterBooking(val, str) {
    this.selectedFilter = val;
    this.selectedFilterStr = str;
    this.getMyBookingList();
  }

  loadMore() {
      this.perPage = this.perPage + this.helpers.loadMore;
      this.getMyBookingList();
  }

  cancelBooking(id, e) {
    e.preventDefault();
    e.stopPropagation();
    this.modalService.open(CancelBookingComponent,
      {size: 'sm', centered: true, windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
      if (result === 'confirm') {
        this.cancelBookingTour(id);
      }
    });
  }

  cancelBookingTour(id) {
    this.spinner.show();
    this.apiService.getCancelBooking(id).subscribe(value => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message);
      this.getMyBookingList();
    });
  }

  navigateToBookingDetails(id) {
    this.router.navigate([`booking-details/${id}`]);
  }
}
