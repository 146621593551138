import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../api.service";
import {CSHelper} from "../../helpers";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  noData = false;
  autoSearchResult = false;
  type: number;
  search: string;
  tourList = [];
  categoryList = [];
  autoSearch = [];
  favoritePlace = '';
  public unsub: any;
  params: boolean = false;
  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              public helpers: CSHelper,
              private spinner: NgxSpinnerService,
              private renderer: Renderer2) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
    this.type = 1
    this.route.queryParams
      .filter(params => params.search)
      .subscribe(params => {
        if (params) {
          this.params =  true
          this.search = params.search.trim();
          this.favoritePlace = params.search.trim();
        }
      });
    this.searchCategoryTourList();
    window.history.pushState('' , '', 'search');
  }

  searchCategoryTourList() {
    if (this.search && this.search.length > 0) {
      if (this.unsub !== undefined) {
        this.unsub.unsubscribe();
      }
      this.unsub = this.apiService.searchCategoryTourList(this.type, this.search).subscribe(data => {
        if (data.meta.total === 0) {
          this.noData = false;
        } else {
          this.noData = true;
        }
        if (this.type === 1) {
          this.tourList = data.data;
        } else {
          this.categoryList = data.data;
        }
      });
    } else {
      this.spinner.hide();
      this.tourList = [];
      this.categoryList = [];
    }
  }
  fetchNews(type: any) {
    if (type === 2) {
      this.type = 1;
    } else {
      this.type = 2;
    }
    this.searchCategoryTourList();
  }
  handleKeywordSuggestions() {
    if (this.favoritePlace.trim() === '') {
      this.apiService.getKeywordSearch().subscribe(value => {
        this.tourList = [];
        this.categoryList = [];
        this.autoSearchResult =  true
        this.autoSearch = value.data;
      });
    }
  }

  handleSearch() {
    this.autoSearch = []
    if (this.favoritePlace.trim() !== '') {
      this.params = true;
      this.search = this.favoritePlace;
      this.autoSearchResult = false;
      this.searchCategoryTourList();
    } else {
      this.params = false;
      this.search = '';
      this.favoritePlace = '';
      this.autoSearchResult =  true;
      this.tourList = [];
      this.categoryList = [];
      this.handleKeywordSuggestions();
    }
  }
  removeSearch() {
    this.params = false
    this.search = '';
    this.favoritePlace = '';
    this.autoSearchResult =  true;
    this.tourList = [];
    this.categoryList = [];
    this.handleKeywordSuggestions();
  }
  searchTours(value) {
    this.autoSearchResult = false
    this.favoritePlace = value.trim();
    this.search = this.favoritePlace;
    this.searchCategoryTourList();
  }
  onClickedOutside(e: Event) {
    if (e) {
      this.autoSearchResult =  false
      this.autoSearch = [];
    }
  }
}
