import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CSHelper {

  headerHide = true;
  footerHide = true;
  Currency = 'rm';
  minEmailAddress = 150;
  minMobileNumber = 5;
  maxMobileNumber = 15;
  mobilePattern = /^[0-9]{5,15}$/;
  regPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$`~!%*+-_^()[#\]?&]{6,}$$/;
  emailPattern = /^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/;
  password_hide = true;
  confirm_password_hide = true;
  url: any = '../assets/images/login/user-default.svg';
  email = '';
  mobile: '';
  country_code: '';
  verifyFromLogin = false;
  resetFor = '';
  sliderFull = false;
  logoScroll = false;
  noDataFound = false;
  tourDefaultImage = '?q=85&h=200&w=300&fit=crop';
  tourCategoryImage = '?q=85&h=200&w=300&fit=crop';
  tourDetailSliderImage = '?w=730&h=365&fit=crop&q=85';
  loadMore = 6;
  perPage = 6;
  public defaultCategory = 'assets/images/not_available_tour.png';
  isLoading = true;
  constructor(private modalService: NgbModal, private toastr: ToastrService) {
  }

  /* Mobile numberOnly Check function */

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  /* common popup close function */

  close() {
    this.modalService.dismissAll();
  }

  /* Password show and hide function */

  checkPassword(val) {
    val === 'password' ? this.password_hide === true ? this.password_hide = false : this.password_hide = true : this.confirm_password_hide === true ? this.confirm_password_hide = false : this.confirm_password_hide = true;
  }

  /* Password MustMatch function */

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  copyToClipboard(data) {
    const el = document.createElement('textarea');
    el.value = data;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toastr.success('Copied!');
  }

  formatDate(date) {
    const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d =  new Date(date).getDate();
    const m = strArray[new Date(date).getMonth()];
    const y = new Date(date).getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + ' ' + m + ' ' + y;
  }


  getEllipseString(str, numOfChars = 20) {
   return (str.length > numOfChars) ? str.trim().slice(0, numOfChars).concat(' ...') : str;
  }

  getReadableDate(date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
    const day = new Date(date).getDate();
    const month = months[new Date(date).getMonth()];
    return `${day} ${month}`;
  }
  setDefaultImage(event, type): void {
    switch (type) {
      case 'category': event.target.src = this.defaultCategory + this.tourDefaultImage;
    }
  }

  parseFloatValue(data, to = 2) {
    return parseFloat(data).toFixed(to);
  }
}


