import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { DatePipe } from "@angular/common";
import { CSHelper } from "./helpers";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "./shared/login/login.component";
import { RegistrationComponent } from "./shared/registration/registration.component";
import { TravelAgentRegistrationComponent } from "./shared/travel-agent-registration/travel-agent-registration.component";
import { ForgotPasswordComponent } from "./shared/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./shared/reset-password/reset-password.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { VerifyMobileNumberComponent } from "./shared/verify-mobile-number/verify-mobile-number.component";
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component";
import { SliderFullScreenComponent } from "./shared/slider-full-screen/slider-full-screen.component";
import { ChangePasswordComponent } from "./shared/change-password/change-password.component";
import { EditProfileDetailComponent } from "./shared/edit-profile-detail/edit-profile-detail.component";
import { DeleteMemberModalComponent } from "./shared/delete-member-modal/delete-member-modal.component";
import { ClickOutsideModule } from "ng-click-outside";
import { LogoutModalComponent } from "./shared/logout-modal/logout-modal.component";
import { CancelBookingComponent } from "./shared/cancel-booking/cancel-booking.component";
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { TravelAgencyLandingPageComponent } from "./pages/travel-agency-landing-page/travel-agency-landing-page.component";
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { QRCodeModule } from 'angularx-qrcode';
import { WechatPayDailogComponent } from './shared/wechat-pay-dailog/wechat-pay-dailog.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent,
    SuccessPageComponent, TravelAgencyLandingPageComponent, TermsConditionsComponent, PrivacyPolicyComponent],
  imports: [
    QRCodeModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
    }),
    ClickOutsideModule
  ],
  providers: [DatePipe, CSHelper],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginComponent,
    WechatPayDailogComponent,
    RegistrationComponent,
    TravelAgentRegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyMobileNumberComponent,
    SliderFullScreenComponent,
    VerifyEmailComponent,
    EditProfileDetailComponent,
    ChangePasswordComponent,
    DeleteMemberModalComponent,
    LogoutModalComponent,
    CancelBookingComponent,
  ],
})
export class AppModule { }
