import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../login/login.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss']
})
export class CommonCardComponent implements OnInit {

  @Input() data;
  displayData: any;

  constructor(public helpers: CSHelper,
              private router: Router,
              private renderer: Renderer2,
              private apiService: ApiService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.displayData = {
      id: this.data.url,
      image: this.data.tour_images.length > 0 && this.data.tour_images.find(v => v.is_cover === '1').url + this.helpers.tourDefaultImage,
      title: this.data.name,
      description: this.data.description,
      days: this.data.total_days,
      favourite: this.data.favourite,
      price: this.data.price,
      upcoming_iterations: this.data.upcoming_iterations
    };
  }

  tourDetail(id) {
    this.router.navigate([`tour-detail/${id}`]);
    this.renderer.removeClass(document.body, 'header-background');
  }

  makeTourFavourite(id, favourite) {
    if (localStorage.getItem('token')) {
      const status: any = favourite === 0 ? 1 : 0;
      const formData = new FormData();
      formData.append('tour_id', id);
      formData.append('status', status);
      this.apiService.postTourFavourite(formData).subscribe(value => {
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.displayData.favourite = status;
        this.apiService.subject.next({tourFavourite: true});
      });
    } else {
      this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    }
  }

}
