import {Component, ElementRef, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CSHelper} from '../../helpers';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../login/login.component';
import {ApiService} from '../../api.service';
import {LogoutModalComponent} from '../logout-modal/logout-modal.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  bodyActive = false;
  languageTitle = 'en';
  innerWidth;
  isLoggedIn: any = localStorage.getItem('token');
  userName: any = localStorage.getItem('userName');
  userImage: any = localStorage.getItem('userImage');

  constructor(@Inject(DOCUMENT) document,
              public helpers: CSHelper,
              public translate: TranslateService,
              private renderer: Renderer2,
              private modalService: NgbModal,
              private el: ElementRef,
              private apiService: ApiService,
              ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(localStorage.getItem('language') === 'ch' ? this.languageTitle = 'ch' : this.languageTitle = 'en') ? browserLang : 'ch');
  }


  ngOnInit() {
    this.apiService.subject.subscribe((v) => {
      if (v.isLoggedIn !== undefined) {
        this.isLoggedIn = v.isLoggedIn;
        this.userImage = v.userImage;
        this.userName = v.userName;
      }
    });
    localStorage.getItem('language') === 'ch' ? this.languageTitle = 'ch' : this.languageTitle = 'en';
    localStorage.getItem('currency') === 'CNY' ? this.helpers.Currency = 'CNY' : this.helpers.Currency = 'MYR';
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 0) {
      const element = document.getElementById('header');
      element.classList.add('sticky');
    } else {
      const element = document.getElementById('header');
      element.classList.remove('sticky');
    }
  }

  menuToggle() {
    this.bodyActive === true ? this.renderer.removeClass(document.body, 'body-scroll') : this.renderer.addClass(document.body, 'body-scroll');
    this.bodyActive === true ? this.bodyActive = false : this.bodyActive = true;
  }

  menuClose() {
    this.bodyActive === true ? this.renderer.removeClass(document.body, 'body-scroll') : this.renderer.addClass(document.body, 'body-scroll');
    this.bodyActive === true ? this.bodyActive = false : this.bodyActive = true;
    this.renderer.removeClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'show');
  }

  ChangeLanguage(language: string) {
    this.languageTitle = language;
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  changeCurrency(currency: string) {
    this.helpers.Currency = currency;
    localStorage.setItem('currency', currency);
  }

  handleLogout() {
    this.modalService.open(LogoutModalComponent, {size: 'sm', centered: true, windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
      if (result === 'confirm') {
        this.confirmLogout();
      }
    });
  }

  confirmLogout() {
    localStorage.clear();
    this.apiService.subject.next({isLoggedIn: false});
    this.closeDropDown();
  }

  closeDropDown() {
    this.bodyActive === true && this.renderer.removeClass(this.el.nativeElement.querySelector('.navbar-collapse'), 'show');
    if (this.bodyActive === true) {
      this.renderer.removeClass(document.body, 'body-scroll');
      this.bodyActive = false;
    }
  }

  login() {
    this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    this.closeDropDown();
  }

}
