import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CommonCardComponent } from './common-card/common-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { TravelAgentRegistrationComponent } from './travel-agent-registration/travel-agent-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerifyMobileNumberComponent } from './verify-mobile-number/verify-mobile-number.component';
import { DetailSliderComponent } from './detail-slider/detail-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SliderFullScreenComponent } from './slider-full-screen/slider-full-screen.component';
import { VerifyEmailComponent } from '../pages/verify-email/verify-email.component';
import { EditProfileDetailComponent } from './edit-profile-detail/edit-profile-detail.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AllMemberComponent } from './all-member/all-member.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { DeleteMemberModalComponent } from './delete-member-modal/delete-member-modal.component';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';
import { CancelBookingComponent } from './cancel-booking/cancel-booking.component';
import { WechatPayDailogComponent } from './wechat-pay-dailog/wechat-pay-dailog.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  exports: [
    CommonModule,
    NavbarComponent,
    FooterComponent,
    CommonCardComponent,
    TranslateModule,
    DetailSliderComponent,
    AllMemberComponent,
    PaymentDetailsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SlickCarouselModule,
    QRCodeModule,
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    CommonCardComponent,
    LoginComponent,
    RegistrationComponent,
    TravelAgentRegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyMobileNumberComponent,
    DetailSliderComponent,
    SliderFullScreenComponent,
    VerifyEmailComponent,
    EditProfileDetailComponent,
    ChangePasswordComponent,
    AllMemberComponent,
    PaymentDetailsComponent,
    DeleteMemberModalComponent,
    LogoutModalComponent,
    CancelBookingComponent,
    WechatPayDailogComponent
  ],
})
export class SharedModule {

}
