import {Component, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-favorite-tours',
  templateUrl: './favorite-tours.component.html',
  styleUrls: ['./favorite-tours.component.scss']
})
export class FavoriteToursComponent implements OnInit {

  tourCardList = [];
  showMoreTours = false;
  perPage = 6;

  constructor(public helpers: CSHelper,
              private renderer: Renderer2,
              private apiService: ApiService,
              private spinner: NgxSpinnerService
              ) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
    this.favouriteTourList(this.perPage);
    this.apiService.subject.subscribe(v => {
      if (v.isLoggedIn !== undefined || v.tourFavourite) {
        this.favouriteTourList(this.perPage);
      }
    });
  }

  favouriteTourList(perPage) {
    this.apiService.getFavouriteTourList({per_page: perPage}).subscribe((value) => {
      value.meta.total <= 0 ? this.helpers.noDataFound = true : this.helpers.noDataFound = false;
      this.showMoreTours = value.meta.total > this.perPage;
      this.tourCardList = value.data;
      if (value.meta.total === value.meta.from) {
        this.showMoreTours = false;
      }
    });
  }

  loadMoreData() {
    this.perPage = this.perPage + this.helpers.loadMore;
    this.favouriteTourList(this.perPage);
  }

}
