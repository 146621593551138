import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeleteMemberModalComponent} from '../delete-member-modal/delete-member-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-all-member',
  templateUrl: './all-member.component.html',
  styleUrls: ['./all-member.component.scss']
})
export class AllMemberComponent implements OnInit {

  @Input() isDetailsPage: boolean;
  @Input() data;
  @Output() emitter: EventEmitter<any> = new EventEmitter<{id: any, name: string}>();

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  editMember(id) {
    this.emitter.emit({id, name: 'edit'});
  }

  deleteMember(id) {
    this.modalService.open(DeleteMemberModalComponent,
      {size: 'sm', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
      if (result === 'confirm') {
        this.emitter.emit({id, name: 'delete'});
      }
    });
  }

}
