import { Component, OnInit } from '@angular/core';
import {CSHelper} from '../../helpers';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

export const newPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if ( !control.parent || !control ) {
    return null;
  }
  const newPassword = control.parent.get('newPassword');
  const currentPassword = control.parent.get('currentPassword');
  if ( newPassword.value === currentPassword.value ) {
    return {passwordsMatching: true};
  }

};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  submitted = false;
  changePasswordFrm: FormGroup;
  currentPasswordHide = true;
  public formValidationMessages: any = {
    currentPassword: [],
    newPassword: [],
    confirmPassword: [],
  };

  constructor(public helpers: CSHelper,
              private formBuilder: FormBuilder,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private activeModal: NgbActiveModal
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm() {
    this.changePasswordFrm = this.formBuilder.group({
      currentPassword: ['', [
        Validators.required,
        Validators.pattern(this.helpers.regPattern)
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.pattern(this.helpers.regPattern),
        newPasswordValidator
      ]],
      confirmPassword: ['', [
        Validators.required,
      ]],
    }, {
       validator: this.helpers.MustMatch('newPassword', 'confirmPassword'),
    });
    this.formValidationMessages = {
      currentPassword: [
        {type: 'required', message: 'please_enter_current_password'},
      ],
      newPassword: [
        {type: 'required', message: 'please_enter_a_new_password'},
        {type: 'pattern', message: 'password_must_be_minimum_6_character_alphanumeric'},
      ],
      confirmPassword: [
        {type: 'required', message: 'please_enter_confirm_password'},
        {type: 'mustMatch', message: 'new_password_and_confirm_password_doesn’t_match'}
      ],
    };
  }

  checkCurrentPassword() {
    this.currentPasswordHide = !this.currentPasswordHide;
  }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordFrm.valid) {
      this.spinner.show();
      this.apiService.changePassword({
        old_password: this.changePasswordFrm.get('currentPassword').value,
        password: this.changePasswordFrm.get('newPassword').value
      }).subscribe((value: any) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message, 'Success');
        this.activeModal.dismiss();
      });
    }
  }

}
