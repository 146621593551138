import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "../login/login.component";
import { VerifyMobileNumberComponent } from "../verify-mobile-number/verify-mobile-number.component";
import { TravelAgentRegistrationComponent } from "../travel-agent-registration/travel-agent-registration.component";
import { CSHelper } from "../../helpers";
import { ApiService } from "../../api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  submitted = false;
  signUpFrm: FormGroup;
  countryCodeList = [];
  selectedCountry: any;
  public formValidationMessages: any = {
    profile_image: [],
    name: [],
    email: [],
    mobile: [],
    password: [],
    confirmPassword: [],
    terms: [],
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    public helpers: CSHelper,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public router: Router
  ) {
    this.createForm();
  }
  public userImage = this.helpers.url;

  private createForm() {
    this.signUpFrm = this.formBuilder.group(
      {
        profile_image: [""],
        name: ["", [Validators.required]],
        email: [
          "",
          [Validators.required, Validators.pattern(this.helpers.emailPattern)],
        ],
        mobile: [
          "",
          [
            Validators.required,
            Validators.minLength(this.helpers.minMobileNumber),
          ],
        ],
        password: [
          "",
          [Validators.required, Validators.pattern(this.helpers.regPattern)],
        ],
        confirmPassword: ["", [Validators.required]],
        terms: [false, [Validators.requiredTrue]],
        country_code: [],
      },
      {
        validator: this.helpers.MustMatch("password", "confirmPassword"),
      }
    );
    this.formValidationMessages = {
      name: [{ type: "required", message: "please_enter_your_name" }],
      email: [
        { type: "required", message: "please_enter_your_email_address" },
        { type: "pattern", message: "please_enter_a_valid_email_address" },
      ],
      mobile: [
        { type: "required", message: "please_enter_your_mobile_number" },
        {
          type: "minlength",
          message: "mobile_number_should_be_between_5_to_15_digits",
        },
      ],
      password: [
        { type: "required", message: "please_enter_your_password" },
        {
          type: "pattern",
          message:
            "password_should_be_minimum_6_characters_and_combination_of_alphanumeric_characters_of_special_symbols",
        },
      ],
      confirmPassword: [
        { type: "required", message: "please_enter_your_confirm_password" },
        {
          type: "mustMatch",
          message: "the_password_and_confirm_password_does_not_match",
        },
      ],
      terms: [
        { type: "required", message: "please_agree_the_terms_and_conditions" },
      ],
    };
  }

  changeCountryCode(e) {
    this.signUpFrm.controls.country_code.setValue(e.target.value);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userImage = reader.result;
        this.signUpFrm.patchValue({
          profile_image: file,
        });
      };
    }
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe((value) => {
      this.countryCodeList = value.data;
      this.signUpFrm.controls.country_code.setValue(
        value.data.find((v) => v.phone_prefix === 86).phone_prefix
      );
      this.selectedCountry = value.data.find(
        (v) => v.phone_prefix === 86
      ).phone_prefix;
    });
  }

  signUp() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signUpFrm.invalid) {
      return;
    }
    const data: Array<string> = [
      "name",
      "email",
      "mobile",
      "password",
      "country_code",
    ];
    const formData = new FormData();
    if (this.signUpFrm.value.profile_image !== "") {
      data.push("profile_image");
    }
    data.map((val: string) => {
      formData.append(val, this.signUpFrm.get(val).value);
    });
    this.spinner.show();
    this.apiService.travelUserSignUp(formData).subscribe((value: any) => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message, "Success", {
        timeOut: 3000,
      });
      if (value.data.mobile_verified === 0) {
        this.helpers.email = value.data.email;
        this.helpers.mobile = value.data.mobile;
        this.helpers.country_code = value.data.country_code;
        this.helpers.verifyFromLogin = false;
        this.activeModal.dismiss();
        this.modalService.open(VerifyMobileNumberComponent, {
          size: "xl",
          windowClass: "common-popup",
          backdropClass: "modal-backdrop-custom",
        });
      }
    });
  }

  login() {
    this.modalService.open(LoginComponent, {
      size: "xl",
      windowClass: "common-popup",
      backdropClass: "modal-backdrop-custom",
    });
    this.activeModal.dismiss();
  }

  navigateTo() {
    window.open("/terms-conditions", "_blank");
  }
}
