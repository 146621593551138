import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import "rxjs/Rx";
import "rxjs-compat/add/operator/catch";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { Payment } from "./models/payment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl: string = environment.apiBaseUrl;
  subject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  getToken(): any {
    return new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
    });
  }

  private handleError(err: any): Observable<any> {
    if (err.error.status === 400) {
      this.toastr.error(err.error.message);
    }
    if (err.status === 401 || err.status === 500) {
      localStorage.clear();
      this.subject.next({ isLoggedIn: false });
    }
    this.spinner.hide();
    return Observable.throwError(err.error);
  }

  travelUserSignUp(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/tourist-registration`, data)
      .catch((x) => this.handleError(x));
  }

  travelAgentSignUp(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/agency-register`, data)
      .catch((x) => this.handleError(x));
  }

  loginApi(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/login`, data)
      .catch((x) => this.handleError(x));
  }

  getWechatLoginQr(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/wechat/qr?isMobile=${data.isMobile}`)
      .catch((x) => this.handleError(x));
  }

  loginWithWechat(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/wechat/code`, data)
      .catch((x) => this.handleError(x));
  }

  getWechatPayQrCode(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/wechat/pay`, {
        params: data
      })
      .catch((x) => this.handleError(x));
  }

  getWechatPayPaymentStatus(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/wechat/pay/status`, data)
      .catch((x) => this.handleError(x));
  }

  processPaymentWithWeChat(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/wechat/pay`, data)
      .catch((x) => this.handleError(x));
  }

  forgotPassword(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/forgot-password`, data)
      .catch((x) => this.handleError(x));
  }

  resetPassword(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/reset-password`, data)
      .catch((x) => this.handleError(x));
  }

  verifyMobileNumber(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/verify-mobile-number`, data)
      .catch((x) => this.handleError(x));
  }

  resendOtp(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/resend-otp`, data)
      .catch((x) => this.handleError(x));
  }

  getCountryCode(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/country-code`)
      .catch((x) => this.handleError(x));
  }

  getEmailVerification(urlToken): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/verify-email/${urlToken}`)
      .catch((x) => this.handleError(x));
  }

  getCategoryList(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/category`)
      .catch((x) => this.handleError(x));
  }

  getCategoryTourList(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/category-tour-list`, {
        params: data,
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getKeywordSearch(): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/web/keyword-suggestion`)
      .catch((x) => this.handleError(x));
  }

  getProfileDetails(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/profile-detail`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  changePassword(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/change-password`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  editProfile(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/update-profile`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  updateProfileMobileVerification(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/mobile-verification`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  updateProfileResendOtp(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/profile/resend-otp`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  resendEmailVerification(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/email-verification`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  postAddFamilyMember(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/family-member/add-edit`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getFamilyMemberList(data): Observable<any> {
    const params =
      data.search && data.search !== "" ? { search: data.search } : {};
    return this.http
      .get<any>(`${this.baseUrl}/web/family-member`, {
        params,
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  deleteFamilyMember(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/family-member/delete/${data}`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  postContactUs(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/contact-us`, data)
      .catch((x) => this.handleError(x));
  }

  postTourFavourite(data): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/web/tour/favourite-unfavourite`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getCmsDetails(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/cms/${data}`)
      .catch((x) => this.handleError(x));
  }

  getTourDetails(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/tour/detail/${data}`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  searchCategoryTourList(type, search): Observable<any> {
    const types = type ? type : 1;
    const searchValue = search ? "&search=" + search : "";
    return this.http
      .get<any>(`${this.baseUrl}/web/search?type=${types}${searchValue}`)
      .catch((x) => this.handleError(x));
  }

  getPromoCodeList(data): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/web/promo-code`, {
        params: {
          per_page: data.per_page,
        },
      })
      .catch((x) => this.handleError(x));
  }

  getFavouriteTourList(data) {
    return this.http
      .get<any>(`${this.baseUrl}/web/favourite/tour`, {
        params: { per_page: data.per_page },
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  postApplyPromoCode(data) {
    return this.http
      .post<any>(`${this.baseUrl}/web/promo-code/apply`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  postBookTour(data) {
    return this.http
      .post<any>(`${this.baseUrl}/web/book-tour`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  checkout(data) {
    return this.http
      .post<Payment>(`${this.baseUrl}/web/checkout`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getPaymentDetails(data) {
    return this.http.get<any>(`${this.baseUrl}/web/success-page/${data}`, {
      headers: this.getToken(),
    });
  }

  getBookingList(data) {
    return this.http
      .get<any>(`${this.baseUrl}/web/my-bookings`, {
        params: data,
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getCancelBooking(data) {
    return this.http
      .get<any>(`${this.baseUrl}/web/cancel-booking/${data}`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getBookingDetails(data) {
    return this.http
      .get<any>(`${this.baseUrl}/web/booking-details/${data}`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  postAddTourReview(data) {
    return this.http
      .post<any>(`${this.baseUrl}/web/add-review`, data, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }

  getFaqs() {
    return this.http
      .get<any>(`${this.baseUrl}/web/faq`, {
        headers: this.getToken(),
      })
      .catch((x) => this.handleError(x));
  }
}
