import { Component, HostListener, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CSHelper } from "../../helpers";
import { ApiService } from "../../api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  autoSearchResult = false;
  favoritePlace;
  innerWidth;
  autoSearch = [];
  categorysAll = [];
  tourCardList = [];
  showViewAll = false;
  showMoreTours = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    public helpers: CSHelper,
    private apiService: ApiService,
    private toastr: ToastrService,
    private _ActivatedRoute: ActivatedRoute
  ) {
    if (this.router.url === "/") {
      this.renderer.addClass(document.body, "header-background");
    }
    this.helpers.logoScroll = true;
    this.innerWidth = window.screen.width;
    if (this.innerWidth <= 991) {
      this.helpers.logoScroll = false;
    }
  }

  ngOnInit() {
    //isMobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code")) {
      const code = urlParams.get("code");
      this.apiService
        .loginWithWechat({
          code,
          isMobile: isMobile,
        })
        .subscribe((value) => {
          if (value.meta.code == 1 && value.meta.token) {
            localStorage.setItem("userName", value.data.name);
            localStorage.setItem("userImage", value.data.profile_image);
            localStorage.setItem("token", value.meta.token);
            this.apiService.subject.next({
              isLoggedIn: true,
              userName: value.data.name,
              userImage: value.data.profile_image,
            });
            this.toastr.success(value.meta.message);

            var snapshot = this._ActivatedRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.code;
            delete params.state;
            this.router.navigate([], { queryParams: params });

            const lastUrl = localStorage.getItem("lastUrl");

            if (lastUrl) {
              window.location.href = lastUrl;
            }
          }
        });
    }

    this.apiService.getCategoryList().subscribe((value) => {
      this.showViewAll = value.data.length > 3;
      if (value.data.length > 3) {
        this.categorysAll = value.data.slice(0, 3);
      } else {
        this.categorysAll = value.data;
      }
    });
    this.getTourListing();
    this.apiService.subject.subscribe((v) => {
      if (v.isLoggedIn !== undefined || v.tourFavourite) {
        this.getTourListing();
      }
    });
  }

  getTourListing() {
    this.apiService.getCategoryTourList({ per_page: 6 }).subscribe((value) => {
      this.showMoreTours = value.meta.total > 6;
      this.tourCardList = value.data;
    });
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 0) {
      this.helpers.logoScroll = false;
    } else {
      this.helpers.logoScroll = this.innerWidth > 991;
    }
  }

  handleKeywordSuggestions() {
    this.autoSearchResult = true;
    this.apiService.getKeywordSearch().subscribe((value) => {
      this.autoSearch = value.data;
    });
  }
  discardSuggestions() {
    this.autoSearchResult = false;
  }

  handleSearch() {
    this.autoSearchResult = false;
  }

  selectCategory(name) {
    this.router.navigate(["category", name]);
  }

  searchTours() {
    // TODO: redirect to listing page with search result
  }
  onClickedOutside(e: Event) {
    if (e) {
      this.autoSearchResult = false;
      this.autoSearch = [];
    }
  }
}
