import {Component, OnInit, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CSHelper} from '../../helpers';
import {ChangePasswordComponent} from '../../shared/change-password/change-password.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditProfileDetailComponent} from '../../shared/edit-profile-detail/edit-profile-detail.component';
import {DeleteMemberModalComponent} from '../../shared/delete-member-modal/delete-member-modal.component';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  myProfileFrm: FormGroup;
  submitted = false;
  userDetails: any = {
    profile_image: '',
    name: '',
    platform_id: '',
    country_code: '',
    mobile: '',
    email: '',
    email_verified: ''
  };
  countryCodeList: any;
  selectedCountry: any;
  editMemberId: any;
  public formValidationMessages: any = {
    name: [],
    email: [],
    mobile: [],
  };
  membersDetails = [];

  constructor(private formBuilder: FormBuilder,
              public helpers: CSHelper,
              private modalService: NgbModal,
              private renderer: Renderer2,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              ) {
    this.createForm();
    this.helpers.logoScroll = false;
    this.renderer.removeClass(document.body, 'header-background');
  }

  ngOnInit() {
    this.apiService.getCountryCode().subscribe(value =>  {
      this.countryCodeList = value.data;
      this.myProfileFrm.controls.country_code.setValue(value.data.find(v => v.phone_prefix === 86).phone_prefix);
      this.selectedCountry = value.data.find(v => v.phone_prefix === 86).phone_prefix;
    });

    this.spinner.show();
    this.apiService.subject.subscribe(v => {
      if (v.profileUpdated) {
        return this.profileDetails();
      }
    });
    this.profileDetails();
    this.familyMemberList();
  }

  profileDetails() {
    this.apiService.getProfileDetails().subscribe(response => {
      this.spinner.hide();
      this.userDetails = response.data;
    });
  }

  private createForm() {
    this.myProfileFrm = this.formBuilder.group({
      name: ['', [
        Validators.required,
      ]],
      email: [''],
      mobile: [''],
      country_code: ['']
    });
    this.formValidationMessages = {
      name: [
        {type: 'required', message: 'please_enter_member_name'},
      ]
    };
  }

  editFamilyMember(member) {
    this.editMemberId = member.id;
    ['name', 'email', 'mobile', 'country_code'].map(v => {
      if (v === 'country_code' && !member.country_code) {
        return this.myProfileFrm.controls[v].setValue(86);
      }
      this.myProfileFrm.controls[v].setValue(member[v] || '');
    });
  }

  myProfile() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.myProfileFrm.valid) {
      const formData =  new FormData();
      ['name', 'email', 'mobile', 'country_code'].map(value => {
        if (value === 'country_code') {
          if (this.myProfileFrm.get('mobile').value === '') { return formData.append(value, ''); }
        }
        formData.append(value, this.myProfileFrm.get(value).value);
      });
      if (this.editMemberId) {
        formData.append('id', this.editMemberId);
      }
      this.spinner.show();
      this.apiService.postAddFamilyMember(formData).subscribe(value => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        }
        this.toastr.success(value.meta.message);
        this.familyMemberList();
        ['name', 'email', 'mobile', 'country_code'].map(v => {
          if (v === 'country_code') { return this.myProfileFrm.controls.country_code.setValue(86); }
          this.myProfileFrm.controls[v].setValue('');
        });
        this.editMemberId = null;
        this.submitted = false;
      });
    }
  }

  changeCountryCode(e) {
    this.myProfileFrm.controls.country_code.setValue(e.target.value);
  }

  editProfileDetail() {

    const modalRef = this.modalService.open(EditProfileDetailComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
    modalRef.componentInstance.userDetails = this.userDetails;
  }

  changePassword() {
    this.modalService.open(ChangePasswordComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
  }

  resendEmail() {
    this.spinner.show();
    this.apiService.resendEmailVerification().subscribe(value => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message);
    });
  }

  familyMemberList() {
    this.apiService.getFamilyMemberList({search: null}).subscribe(value => {
      this.membersDetails = value.data;
    });
  }

  confirmFamilyMember(id) {
    this.modalService.open(DeleteMemberModalComponent,
      {size: 'sm', centered: true, windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'}).result.then((result) => {
        if (result === 'confirm') {
        this.deleteFamilyMember(id);
      }
    });
  }

  deleteFamilyMember(id) {
    this.spinner.show();
    this.apiService.deleteFamilyMember(id).subscribe(value => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      }
      this.toastr.success(value.meta.message);
      this.familyMemberList();
    });
  }

}
