import {Component, OnInit, Renderer2} from '@angular/core';
import {CSHelper} from '../../helpers';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notification = [];
  notificationData = {
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.',
    date: '25 Nov 2019, 10:30 AM'
  };

  constructor(public helpers: CSHelper, private renderer: Renderer2) {
    this.helpers.logoScroll = false;
    this.renderer.removeClass(document.body, 'header-background');
    for (let i = 0; i < 10; i++) {
      this.notification.push(this.notificationData);
    }
  }

  ngOnInit() {
  }

}
