import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CSHelper} from '../../helpers';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";

@Component({
  selector: 'app-travel-agent-registration',
  templateUrl: './travel-agent-registration.component.html',
  styleUrls: ['./travel-agent-registration.component.scss']
})
export class TravelAgentRegistrationComponent implements OnInit {

  travelAgentFrm: FormGroup;
  submitted = false;
  countryCodeList = [];
  selectedCountry: any;
  public formValidationMessages: any = {
    agency_logo: [],
    name: [],
    contact_person_name: [],
    email: [],
    mobile: [],
    password: [],
    confirmPassword: [],
    address: [],
    terms: [],
  };

  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder,
              public helpers: CSHelper,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              public activeModal: NgbActiveModal,
              public router: Router
  ) {
    this.createForm();
  }
  public userImage = this.helpers.url;

  ngOnInit() {
    this.apiService.getCountryCode().subscribe(value =>  {
      this.countryCodeList = value.data;
      this.travelAgentFrm.controls.country_code.setValue(value.data.find(v => v.phone_prefix === 86).phone_prefix);
      this.selectedCountry = value.data.find(v => v.phone_prefix === 86).phone_prefix;
    });
  }

  private createForm() {
    this.travelAgentFrm = this.formBuilder.group({
      agency_logo: ['', Validators.required],
      name: ['', [
        Validators.required,
      ]],
      contact_person_name: ['', [
        Validators.required,
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(this.helpers.emailPattern),
      ]],
      mobile: ['', [
        Validators.required,
        Validators.minLength(this.helpers.minMobileNumber)
      ]],
      password: ['', [
        Validators.required,
        Validators.pattern(this.helpers.regPattern)
      ]],
      confirmPassword: ['', [
        Validators.required,
      ]],
      address: ['', [
        Validators.required,
      ]],
      terms: [false, [
        Validators.requiredTrue,
      ]],
      country_code: []
    }, {
      validator: this.helpers.MustMatch('password', 'confirmPassword')
    });
    this.formValidationMessages = {
      agency_logo: [
        {type: 'required', message: 'please_upload_any_profile_image'},
      ],
      name: [
        {type: 'required', message: 'please_enter_the_name_of_agency'},
      ],
      contact_person_name: [
        {type: 'required', message: 'please_enter_the_name_of_contact_person'},
      ],
      email: [
        {type: 'required', message: 'please_enter_your_email_address'},
        {type: 'pattern', message: 'please_enter_a_valid_email_address'},
      ],
      mobile: [
        {type: 'required', message: 'please_enter_your_mobile_number'},
        {type: 'minlength', message: 'mobile_number_should_be_between_5_to_15_digits'},
      ],
      password: [
        {type: 'required', message: 'please_enter_your_password'},
        {type: 'pattern', message: 'password_should_be_minimum_6_characters_and_combination_of_alphanumeric_characters_of_special_symbols'}
      ],
      confirmPassword: [
        {type: 'required', message: 'please_enter_your_confirm_password'},
        {type: 'mustMatch', message: 'the_password_and_confirm_password_does_not_match'}
      ],
      address: [
        {type: 'required', message: 'please_enter_address_of_the_agency'},
      ],
      terms: [
        {type: 'required', message: 'please_agree_the_terms_and_conditions'},
      ],
    };
  }

  changeCountryCode(e) {
    this.travelAgentFrm.controls.country_code.setValue(e.target.value);
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userImage = reader.result;
        this.travelAgentFrm.patchValue({
          agency_logo: file
        });
      };
    }

  }

  travelAgent() {
    this.submitted = true;
    if (this.travelAgentFrm.invalid) {
      return;
    }

    const data = ['name', 'email', 'country_code', 'mobile', 'contact_person_name', 'address', 'password', 'agency_logo'];
    const formData = new FormData();
    data.map(val => {
      formData.append(val, this.travelAgentFrm.get(val).value);
    });
    this.spinner.show();
    this.apiService.travelAgentSignUp(formData).subscribe((value) => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      } else {
        this.activeModal.close();
        this.toastr.success(value.meta.message, 'Success', {
          timeOut: 4000
        });
      }
    });
  }

  navigateTo() {
    this.activeModal.dismiss();
    this.router.navigate(['terms-conditions']);
  }

}
