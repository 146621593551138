import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { SharedModule } from "./shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TourDetailComponent } from "./pages/tour-detail/tour-detail.component";
import {
  NgbDropdownModule,
  NgbRatingModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component";
import { MyProfileComponent } from "./pages/my-profile/my-profile.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { CmsComponent } from "./pages/cms/cms.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { PromocodesComponent } from "./pages/promocodes/promocodes.component";
import { SearchComponent } from "./pages/search/search.component";
import { CategoryComponent } from "./pages/category/category.component";
import { FavoriteToursComponent } from "./pages/favorite-tours/favorite-tours.component";
import { MyBookingComponent } from "./pages/my-booking/my-booking.component";
import { BookNowComponent } from "./pages/book-now/book-now.component";
import { BookingDetailsComponent } from "./pages/booking-details/booking-details.component";
import { NotificationComponent } from "./pages/notification/notification.component";
import { PageNotfoundComponent } from "./pages/page-notfound/page-notfound.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { SuccessPageComponent } from "./pages/success-page/success-page.component";
import { AuthGuard } from "./auth/auth.guard";
import { ClickOutsideModule } from "ng-click-outside";
import { TravelAgencyLandingPageComponent } from "./pages/travel-agency-landing-page/travel-agency-landing-page.component";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: {
      title: "Home",
    },
  },
  {
    path: "verify-email/:urlToken",
    component: VerifyEmailComponent,
    data: {
      title: "Verify Email",
    },
  },
  {
    path: "tour-detail/:id",
    component: TourDetailComponent,
    data: {
      title: "Tour Detail",
    },
  },
  {
    path: "my-profile",
    component: MyProfileComponent,
    canActivate: [AuthGuard],
    data: {
      title: "My Profile",
    },
  },
  {
    path: "contact-us",
    component: ContactUsComponent,
    data: {
      title: "Contact Us",
    },
  },
  {
    path: "terms-conditions",
    component: TermsConditionsComponent,
    data: {
      title: "Terms Conditions",
      cmsLink: "terms-and-conditions",
    },
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: {
      title: "Privacy Policy",
      cmsLink: "privacy-policy",
    },
  },
  {
    path: "about-us",
    component: CmsComponent,
    data: {
      title: "About Us",
      cmsLink: "about-us",
    },
  },
  {
    path: "faq",
    component: FaqComponent,
    data: {
      title: "FAQs",
    },
  },
  {
    path: "promocodes",
    component: PromocodesComponent,
    data: {
      title: "Promo codes",
    },
  },
  {
    path: "search",
    component: SearchComponent,
    data: {
      title: "Search",
    },
  },
  {
    path: "be-a-travel-agency",
    component: TravelAgencyLandingPageComponent,
    data: {
      title: "Become a travel agency",
    },
  },
  {
    path: "category",
    component: CategoryComponent,
    data: {
      title: "Categories",
    },
  },
  {
    path: "category/:name",
    component: CategoryComponent,
    data: {
      title: "Categories",
    },
  },
  {
    path: "favorite-tours",
    component: FavoriteToursComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Favourite Tours",
    },
  },
  {
    path: "my-booking",
    canActivate: [AuthGuard],
    component: MyBookingComponent,
    data: {
      title: "My Booking",
    },
  },
  {
    path: "book-now",
    component: BookNowComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Book Now",
    },
  },
  {
    path: "booking-details/:id",
    component: BookingDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Booking Details",
    },
  },
  {
    path: "notification",
    component: NotificationComponent,
    data: {
      title: "Notification",
    },
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
    data: {
      title: "Maintenance",
    },
  },
  {
    path: "not-found",
    component: PageNotfoundComponent,
    data: {
      title: "Page Not Found",
    },
  },
  {
    path: "success-page/:ref_no",
    component: SuccessPageComponent,
    data: {
      title: "Payment Successful",
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "top" }),
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgbRatingModule,
    NgbDropdownModule,
    NgbTabsetModule,
    ClickOutsideModule,
  ],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    TourDetailComponent,
    MyProfileComponent,
    ContactUsComponent,
    CmsComponent,
    FaqComponent,
    MyProfileComponent,
    PromocodesComponent,
    SearchComponent,
    CategoryComponent,
    FavoriteToursComponent,
    MyBookingComponent,
    BookNowComponent,
    BookingDetailsComponent,
    MyBookingComponent,
    NotificationComponent,
    MaintenanceComponent,
    PageNotfoundComponent,
  ],
})
export class AppRoutingModule {}
