import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CSHelper} from '../../helpers';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {SliderFullScreenComponent} from '../slider-full-screen/slider-full-screen.component';
import {SlickCarouselComponent} from 'ngx-slick-carousel';

@Component({
  selector: 'app-detail-slider',
  templateUrl: './detail-slider.component.html',
  styleUrls: ['./detail-slider.component.scss']
})
export class DetailSliderComponent implements OnInit {

  @Input() images;
  @Input() sliderFull;
  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModalThumbnail', { static: true }) slickModalThumbnail: SlickCarouselComponent;


  constructor(public helpers: CSHelper, private modalService: NgbModal, private config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  slides = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: false,
    speed: 400,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    asNavFor: '.slider-nav',
    initialized: true,
    prevArrow: '<button type=\'button\' class=\'slick-prev icon-back\'></button>',
    nextArrow: '<button type=\'button\' class=\'slick-next icon-next\'></button>'
  };

  slideConfigThumbnail = {
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: false,
    speed: 400,
    centerPadding: '15px',
    asNavFor: '.slider-for',
    focusOnSelect: true,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };

  ngOnInit() {
    this.slides = this.images;
  }
  sliderFullScreen() {
    // tslint:disable-next-line:max-line-length
    const modalRef = this.modalService.open(SliderFullScreenComponent, {size: 'xl', windowClass: 'slider-popup', backdropClass: 'modal-backdrop-custom', backdrop: 'static', keyboard: false});
    modalRef.componentInstance.images = this.slides;
    this.helpers.sliderFull = true;
  }

}
