import { Component, Input, OnInit } from '@angular/core';
import { CSHelper } from "../../helpers";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../api.service';

interface PaymentStatus {
  code: string,
  data: {
    outletName: string,
    transactionAmt: number,
    transactionStatus: number,
    walletType: string,
    transDateTime: string,
    mid: string,
    shopId: string,
    transactionId: string,
    tid: string
  },
  date: string,
  msg: string,
  result: boolean
}

@Component({
  selector: 'app-wechat-pay-dailog',
  templateUrl: './wechat-pay-dailog.component.html',
  styleUrls: ['./wechat-pay-dailog.component.scss'],
})
export class WechatPayDailogComponent implements OnInit {

  url: string = '';
  time: number = 600;
  remainingTime: string;
  timerInterval: any;
  paymentStatusInterval: any;
  payableAmount: number;

  wechatLogoImageSrc: string = 'assets/images/logos/wechat-pay.svg';
  successSrc: string = 'assets/images/icons/success.svg';
  failedSrc: string = 'assets/images/icons/error.svg';

  isSuccess: boolean = false;
  isFailed: boolean = false;
  removeQrCode: boolean = false;

  paymentStatusMsg: string = "";

  @Input() public data;
  constructor(
    public helpers: CSHelper,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
  ) {
  }

  ngOnInit() {
    this.url = this.data.urlString;
    this.payableAmount = this.data.amount;
    this.startTimer();
    this.checkPaymentStatus();
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
      }

      this.remainingTime = this.transform(this.time);

    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  checkPaymentStatus(): void {
    const transactionId = this.data.transactionId;
    const ref_no = this.data.ref_no;
    const tour_id = this.data.tour_id;
    const email = this.data.email;

    this.paymentStatusInterval = setInterval(() => {

      this.apiService.getWechatPayPaymentStatus({ transactionId }).subscribe((paymentStatus: PaymentStatus) => {

        if (paymentStatus.data.transactionStatus === 1) {

          this.paymentStatusMsg = "Paid Successfully!";
          this.isSuccess = true;
          this.removeQrCode = true;

          const data = {
            ref_no: ref_no,
            tour_id: tour_id,
            transactionId: transactionId,
            transactionAmt: this.data.amount,
            email: email
          }

          //store payment details
          this.apiService.processPaymentWithWeChat(data).subscribe((response) => {

            if (response.redirect_url) {

              setTimeout(() => {

                this.activeModal.close();
                clearInterval(this.timerInterval);
                clearInterval(this.paymentStatusInterval);
                document.location.href = response.redirect_url;

              }, 3000);

            }

          });


        } else if (paymentStatus.data.transactionStatus === 4) {

          this.paymentStatusMsg = "Transaction Failed!";
          this.isFailed = true;
          this.removeQrCode = true;

          setTimeout(() => {

            this.activeModal.close();
            clearInterval(this.timerInterval);
            clearInterval(this.paymentStatusInterval);

          }, 3000);

        } else if (this.time === 0) {

          this.paymentStatusMsg = "Transaction Timeout!";
          this.isFailed = true;
          this.removeQrCode = true;

          setTimeout(() => {

            this.activeModal.close();
            clearInterval(this.timerInterval);
            clearInterval(this.paymentStatusInterval);

          }, 3000);

        }

      });

    }, 10000);

  }

}
