import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { CSHelper } from "../../helpers";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  faqs = [];

  constructor(
    public router: Router,
    public helpers: CSHelper,
    private renderer: Renderer2,
    private apiService: ApiService
  ) {
    this.renderer.removeClass(document.body, "header-background");
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
    this.apiService.getFaqs().subscribe((response) => {
      this.faqs = response.data;
    });
  }

  toContactUs() {
    this.router.navigate(["/contact-us"]);
  }
}
