import { Component, OnInit } from '@angular/core';
import {CSHelper} from "../../helpers";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(private helpers: CSHelper) {
    this.helpers.headerHide = false;
    this.helpers.footerHide = false;
  }

  ngOnInit() {
  }

}
