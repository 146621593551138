import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../shared/login/login.component';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  urlToken: any;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute
  ) {
    this.urlToken = this.activeRoute.snapshot.paramMap.get('urlToken');
  }

  ngOnInit() {
    this.spinner.show();
    this.apiService.getEmailVerification(this.urlToken).subscribe(response => {
      this.spinner.hide();
      if (response.meta.code === 0) {
        this.toastr.error(response.meta.message);
        this.router.navigate(['/']);
      } else if (response.meta.code === 1) {
        this.toastr.success(response.meta.message);
        this.router.navigate(['/']).then(() => {
          this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
        });
      }
    });
  }
}
