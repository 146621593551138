import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CSHelper} from '../../helpers';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  categoryHide = false;
  height = 250;
  leftCategoryHeight;
  tourCardList = [];
  categorysAll = [];
  showMoreTours = false;
  stopSticky = true;
  slugUrl = '';
  categoryTitle;
  mode = false;
  requestParam;
  perPage = 6;
  viewHeight: number;


  @ViewChild('content', {static: true}) elementView: ElementRef;
  @ViewChild('contentMain', {static: true}) main: ElementRef;


  constructor(public helpers: CSHelper,
              private renderer: Renderer2,
              private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: Title) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
    this.leftCategoryHeight = window.innerHeight - this.height;
    this.viewHeight = window.innerHeight - 200;
  }

  ngOnInit() {
    this.slugUrl = this.route.snapshot.params.name;
    this.slugUrl === undefined ? this.categoryTitle = 'All' : this.categoryTitle;
    this.getTourListing(this.slugUrl);
    this.getCategoryList();
    this.apiService.subject.subscribe(v => {
      if ((v.isLoggedIn !== undefined || v.tourFavourite)) {
        this.getTourListing(this.slugUrl);
      }
    });
    this.requestParam = {
      per_page: this.perPage
    };
  }

  categoryToggle() {
    this.categoryHide = this.categoryHide !== true;
  }

  @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
    if (!this.stopSticky) {
      if (this.isScrolledIntoView()) {
        const element = document.getElementById('category_nav');
        element.classList.add('sticky');
        const height = (this.elementView.nativeElement as HTMLElement).getBoundingClientRect().height;
        if (height <= this.leftCategoryHeight) {
          const ele = document.getElementById('category_nav');
          ele.classList.add('sticky-remove');
        }
      } else if (!this.isScrolledIntoView()) {
        const element = document.getElementById('category_nav');
        element.classList.remove('sticky');
      }
    } else {
      this.stopSticky = false;
    }
  }

  isScrolledIntoView() {
    const element = document.getElementsByClassName('footer')[0];
    const rect = element.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    const scrollHeight = window.innerHeight + this.height;
    return (elemTop >= 0) && (elemBottom <= scrollHeight);
  }

  getTourListing(category) {
    this.setParametrs(category);
    this.apiService.getCategoryTourList(this.requestParam).subscribe(value => {
      value.meta.total <= 0 ? this.helpers.noDataFound = true : this.helpers.noDataFound = false;
      this.showMoreTours = value.meta.total > this.perPage;
      if (this.mode === true) {
        this.tourCardList = value.data;
        this.mode = false;
      } else {
        this.tourCardList = value.data;
      }
    });
  }

  getCategoryList() {
    this.apiService.getCategoryList().subscribe(value => {
      this.categorysAll = value.data;
      if (this.slugUrl) {
        const catTitle = this.categorysAll.filter(title => title.url === this.slugUrl && title.name);
        this.categoryTitle = catTitle[0].name;
        this.titleService.setTitle(catTitle[0].name + ' | 12317U.com');
      }
      const selectedUrlIndex = this.categorysAll.findIndex(x => x.url === this.slugUrl);
      if (selectedUrlIndex === -1 && this.slugUrl) {
          this.router.navigate(['not-found']);
        }
    });
  }

  selectCategory(item) {
    this.perPage = 6;
    this.mode = false;
    this.categoryTitle = item.name;
    window.history.pushState('' , '', 'category/' + item.url);
    this.getTourListing(item.url);
    this.slugUrl = item.url;
    this.titleService.setTitle(this.categoryTitle + ' | 12317U.com');
    this.categoryHide = this.categoryHide !== true;
  }

  loadMore() {
    this.mode = true;
    this.perPage = this.perPage + this.helpers.loadMore;
    this.getTourListing(this.slugUrl);
  }

  selectCategoryAll() {
    this.mode = false;
    this.slugUrl = '';
    this.categoryTitle = 'All';
    this.titleService.setTitle(this.categoryTitle + ' | 12317U.com');
    window.history.pushState('' , '', 'category');
    this.getTourListing('');
    this.categoryHide = this.categoryHide !== true;
  }

  setParametrs(id) {
    this.requestParam = {
      per_page: this.perPage
    };
    if (id) {
      this.requestParam.category = id;
    }
  }

}
