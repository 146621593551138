import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CSHelper } from "../../helpers";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { WechatPayDailogComponent } from '../../shared/wechat-pay-dailog/wechat-pay-dailog.component';

@Component({
  selector: "app-book-now",
  templateUrl: "./book-now.component.html",
  styleUrls: ["./book-now.component.scss"],
})
export class BookNowComponent implements OnInit, OnDestroy {
  bookNowFrm: FormGroup;
  submitted = false;
  public formValidationMessages: any = {
    name: [],
    email: [],
  };
  membersDetails = JSON.parse(localStorage.getItem("memberDetails")) || [];
  memberCount;
  bookTourData: any;
  countryCodeList = [];
  selectedCountry: any;

  selectedPaymentMethod: any;
  totalAmount: any;
  selectedId: any;
  selectSuggested = false;
  searchResult = [];
  paymentDetails;

  constructor(
    private formBuilder: FormBuilder,
    public helpers: CSHelper,
    private modalService: NgbModal,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private router: Router,

  ) {
    this.createForm();
    this.helpers.logoScroll = false;
    this.renderer.removeClass(document.body, "header-background");
    this.memberCount = this.membersDetails.length;
    this.bookTourData = JSON.parse(localStorage.getItem("bookTourData"));
  }

  ngOnInit() {
    if (!localStorage.getItem("bookTourData")) {
      return this.router.navigate(["/"]);
    }
    this.apiService.getCountryCode().subscribe((value) => {
      this.countryCodeList = value.data;
      this.bookNowFrm.controls.country_code.setValue(
        value.data.find((v) => v.phone_prefix === 86).phone_prefix
      );
      this.selectedCountry = value.data.find(
        (v) => v.phone_prefix === 86
      ).phone_prefix;
    });
    this.getUserDetails();
    this.apiService.subject.subscribe((v) => {
      if (v.isLoggedIn) {
        this.getUserDetails();
      }
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("memberDetails");
    localStorage.removeItem("bookTourData");
  }

  getUserDetails() {
    if (localStorage.getItem("token")) {
      if (
        !localStorage.getItem("memberDetails") ||
        JSON.parse(localStorage.getItem("memberDetails")).length <= 0
      ) {
        const data = ["name", "email", "mobile", "country_code"];
        this.spinner.show();
        this.apiService.getProfileDetails().subscribe((value) => {
          this.spinner.hide();
          data.map((v) => {
            this.bookNowFrm.controls[v].setValue(value.data[v]);
          });
        });
      }
    }
    return;
  }

  private createForm() {
    this.bookNowFrm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", Validators.pattern(this.helpers.emailPattern)],
      mobile: [],
      country_code: [""],
    });
    this.formValidationMessages = {
      name: [{ type: "required", message: "please_enter_member_name" }],
      email: [
        { type: "pattern", message: "please_enter_a_valid_email_address" },
      ],
    };
  }

  changeCountryCode(e) {
    this.bookNowFrm.controls.country_code.setValue(e.target.value);
  }

  addMember() {
    this.submitted = true;
    if (this.bookNowFrm.valid) {
      const obj = {};
      const data = ["name", "email", "mobile", "country_code"];

      // add member data to localstorage
      data.map((value) => {
        if (value === "country_code") {
          if (this.bookNowFrm.get("mobile").value === "") {
            return (obj[value] = "");
          }
        }
        obj[value] = this.bookNowFrm.get(value).value;
      });

      if (this.selectedId) {
        const arr = JSON.parse(localStorage.getItem("memberDetails"));
        this.membersDetails = arr.filter((val) => val.id !== this.selectedId);
        this.membersDetails.push({ id: this.selectedId, ...obj });
      } else {
        this.membersDetails.push({
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          ...obj,
        });
      }
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(this.membersDetails)
      );
      if (this.selectedId && !this.selectSuggested) {
        this.toastr.success("Details Updated!");
      } else {
        this.toastr.success("Member Added!");
      }
      this.selectedId = null;
      this.selectSuggested = false;

      // reset form
      data.map((v) => {
        if (v === "country_code") {
          return this.bookNowFrm.controls.country_code.setValue(86);
        }
        this.bookNowFrm.controls[v].setValue("");
      });
      this.submitted = false;
    }
  }

  updateMember(e) {
    if (e.name === "edit") {
      this.selectedId = e.id;
      const arr = JSON.parse(localStorage.getItem("memberDetails")).find(
        (val) => val.id === e.id
      );
      const data = ["name", "email", "mobile", "country_code"];
      data.map((v) => {
        if (v === "country_code") {
          if (arr[v] === "") {
            return this.bookNowFrm.controls[v].setValue(86);
          }
        }
        this.bookNowFrm.controls[v].setValue(arr[v]);
      });
    } else if (e.name === "delete") {
      const arr: Array<any> = JSON.parse(localStorage.getItem("memberDetails"));
      this.membersDetails = arr.filter((val) => val.id !== e.id);
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(this.membersDetails)
      );
      this.toastr.success("Member removed!");
    }
  }

  searchFamily(e) {
    if (e.target.value !== "") {
      this.apiService
        .getFamilyMemberList({ search: e.target.value })
        .subscribe((value) => {
          this.searchResult = value.data;
        });
    } else {
      this.searchResult = [];
    }
  }

  handleFillForm(item) {
    this.selectedId = item.id;
    this.selectSuggested = true;
    const data = ["name", "email", "mobile", "country_code"];
    data.map((v) => {
      if (v === "country_code") {
        if (item[v] === "") {
          return this.bookNowFrm.controls[v].setValue(86);
        }
      }
      this.bookNowFrm.controls[v].setValue(item[v]);
    });
    this.searchResult = [];
  }

  bookTour(paymentData) {
    const formData = new FormData();
    if (paymentData && paymentData["promoCodeData"]) {
      formData.append("promocode_id", paymentData["promoCodeData"].id);
    }
    formData.append("tour_id", this.bookTourData.tour_id);
    formData.append("iteration_id", this.bookTourData.iteration_id);
    formData.append(
      "tour_plan",
      this.bookTourData.type === "budget_hotel"
        ? "1"
        : this.bookTourData.type === "standard_hotel"
          ? "2"
          : "3"
    );
    const arr = JSON.parse(localStorage.getItem("memberDetails")).map((v) => {
      const obj = {};
      ["name", "email", "mobile", "country_code"].forEach(
        (k) => (obj[k] = v[k])
      );
      return obj;
    });
    formData.append("members", JSON.stringify(arr));

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var bookingData = JSON.stringify(object);

    localStorage.setItem("bookingData", bookingData);

    this.selectedPaymentMethod = paymentData["paymentMethod"];

    this.totalAmount = this.bookTourData.price * this.membersDetails.length;

    this.paymentDetails = {
      name: this.membersDetails[0]["name"],
      email: this.membersDetails[0]["email"],
      tour_id: this.bookTourData.tour_id,
      amount: this.totalAmount,
      currency: "MYR",
      payment_option: this.selectedPaymentMethod,
    };

    if (paymentData["promoCodeData"]) {
      this.paymentDetails["amount"] = this.helpers.parseFloatValue(
        this.totalAmount -
        this.bookTourData.price *
        this.membersDetails.length *
        (paymentData["promoCodeData"].discount_percent / 100)
      );
    } else {
      this.paymentDetails["amount"] = this.totalAmount;
    }

    //wechat pay
    if (this.selectedPaymentMethod === "WeChat") {

      this.spinner.show();

      // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      this.apiService.getWechatPayQrCode({ amount: this.paymentDetails["amount"] }).subscribe((data) => {

        const modalRef = this.modalService.open(WechatPayDailogComponent, { windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom', backdrop: 'static' });
        modalRef.componentInstance.data = { ...data.data, amount: this.paymentDetails["amount"], tour_id: this.bookTourData.tour_id, email: this.paymentDetails['email'] };

      });

      this.spinner.hide();

    } else {

      this.spinner.show();

      this.apiService.checkout(this.paymentDetails).subscribe((paymentValue) => {

        this.spinner.hide();

        document.location.href = paymentValue.redirect_url;

      });

    }


  }
}
