import { Component, OnInit } from '@angular/core';
import {CSHelper} from '../../helpers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../login/login.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordFrm: FormGroup;
  submitted = false;
  public formValidationMessages: any = {
    verificationCode: [],
    password: [],
    confirmPassword: []
  };

  constructor(public helpers: CSHelper,
              public formBuilder: FormBuilder,
              private apiService: ApiService,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              ) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm() {
    this.resetPasswordFrm = this.formBuilder.group({
      verificationCode: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      password: ['', [
        Validators.required,
        Validators.pattern(this.helpers.regPattern)
      ]],
      confirmPassword: ['', [
        Validators.required,
      ]],
    }, {
      validator: this.helpers.MustMatch('password', 'confirmPassword')
    });
    this.formValidationMessages = {
      verificationCode: [
        {type: 'required', message: 'please_enter_verification_code'},
        {type: 'minlength', message: 'please_enter_valid_verification_code'},
      ],
      password: [
        {type: 'required', message: 'please_enter_a_new_password'},
        {type: 'pattern', message: 'password_should_be_minimum_6_characters_and_combination_of_alphanumeric_characters_of_special_symbols'}
      ],
      confirmPassword: [
        {type: 'required', message: 'please_enter_confirm_password'},
        {type: 'mustMatch', message: 'new_password_and_confirm_password_doesn’t_match'}
      ],
    };
  }

  forgotPassword() {
    const formData = new FormData();
    if (this.helpers.mobile) {
      formData.append('mobile', this.helpers.mobile);
      formData.append('type', '2');
      formData.append('country_code', this.helpers.country_code);
    } else {
      formData.append('email', this.helpers.email);
      formData.append('type', '1');
    }

    this.spinner.show();
    this.apiService.forgotPassword(formData).subscribe((value: any) => {
      this.spinner.hide();
      if (value.meta.code === 0) {
        return this.toastr.error(value.meta.message);
      } else {
        this.toastr.success(value.meta.message);
        this.resetPasswordFrm.controls.verificationCode.setValue('');
      }
    });
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordFrm.valid) {
      const formData = new FormData();
      formData.append('verification_code', this.resetPasswordFrm.get('verificationCode').value);
      formData.append('password', this.resetPasswordFrm.get('password').value);
      this.spinner.show();
      this.apiService.resetPassword(formData).subscribe((value: any) => {
        this.spinner.hide();
        if (value.meta.code === 0) {
          return this.toastr.error(value.meta.message);
        } else {
          this.helpers.email = '';
          this.helpers.country_code = '';
          this.helpers.mobile = '';
          this.toastr.success(value.meta.message);
          this.modalService.open(LoginComponent, {size: 'xl', windowClass: 'common-popup', backdropClass: 'modal-backdrop-custom'});
          this.activeModal.dismiss();
        }
      });
    }
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    }
  }

}
