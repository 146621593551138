import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CSHelper} from '../../helpers';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

  seoUrl;
  title: string;
  description: any;

  constructor(private route: Router,
              public helpers: CSHelper,
              private renderer: Renderer2,
              private apiService: ApiService,
              private activeRoute: ActivatedRoute,
              private toastr: ToastrService
  ) {
    this.renderer.removeClass(document.body, 'header-background');
    this.helpers.logoScroll = false;
  }

  ngOnInit() {
    this.seoUrl = this.route.url;
    this.getCms();
  }

  getCms() {
    this.apiService.getCmsDetails(this.activeRoute.snapshot.data.cmsLink).subscribe(value => {
      if (value.meta.code === 0) {
        return this.toastr.error('Something went wrong!');
      }
      this.title = value.data.title;
      this.description = value.data.description;
    });
  }
}
