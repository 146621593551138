import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CSHelper} from "../../helpers";

@Component({
  selector: 'app-slider-full-screen',
  templateUrl: './slider-full-screen.component.html',
  styleUrls: ['./slider-full-screen.component.scss']
})
export class SliderFullScreenComponent implements OnInit {

  @Input() public images;
  constructor(private modalService: NgbModal, private helpers: CSHelper) {
  }

  ngOnInit() {
  }

  sliderClose() {
    this.modalService.dismissAll();
    this.helpers.sliderFull = false;
  }

}
